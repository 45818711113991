import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { GET_USER_PROFILE } from "../Apis";
import styles from "./Profile.module.css";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
const Profile = () => {
  let { handleSignOut, isTokenExpired, refreshAccessToken, accessToken } =
    useContext(UserContext);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchUserData = async () => {
    if (isTokenExpired(accessToken)) accessToken = await refreshAccessToken();
    try {
      const response = await axios.get(GET_USER_PROFILE, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      setLoading(false);
      setProfile(response.data.user);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className={styles.profileSkeleton}>
        {window.innerWidth > 550 ? (
          <>
            <Skeleton height={30} width={200} />
            <Skeleton height={20} width={400} />
            <Skeleton height={20} width={400} />
            <Skeleton height={30} width={300} />
          </>
        ) : (
          <>
            <Skeleton height={25} width={150} />
            <Skeleton height={20} width={300} />
            <Skeleton height={20} width={300} />
            <Skeleton height={25} width={250} />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.accountInfo}>
        <ul>
          <li>
            <b>Account Information</b>
          </li>
          <li>
            <b>Name</b> {profile?.name}
          </li>
          <li>
            <b>Email</b> {profile?.email}
          </li>
          <li>
            <b>Phone</b> {profile?.phone}
          </li>

          <button onClick={handleSignOut}>Log Out</button>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
