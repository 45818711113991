import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { GET_PRODUCT_BY_CATEGORY } from "../Apis";
import axios from "axios";
import styles from "./FullProducts.module.css";
import ProductCard from "../ProductCard/ProductCard";
import ProductDetailModal from "../../Modals/ProductDetails";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FilterIcon from "../../assets/filter.svg";
const FullProduct = () => {
  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page");
  const [sortOption, setSortOption] = useState("Featured");
  const [openFilters, setOpenFilters] = useState(false);
  const [FullProductData, setFullProductData] = useState(null);
  if (category) document.title = `${category} - Bandhej Hub`;

  const sortProducts = (products) => {
    const data = products.products;
    switch (sortOption) {
      case "Featured":
        return [...data];
      case "priceAccending":
        return [...data].sort((a, b) => a.showPrice - b.showPrice);
      case "priceDecending":
        return [...data].sort((a, b) => b.showPrice - a.showPrice);
      case "AtoZ":
        return [...data].sort((a, b) => a.name.localeCompare(b.name));
      case "ZtoA":
        return [...data].sort((a, b) => b.name.localeCompare(a.name));
      case "NewToOld":
        return [...data].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "OldToNew":
        return [...data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      default:
        return data;
    }
  };

  const FetchFullProductList = async () => {
    try {
      const response = await axios.get(
        `${GET_PRODUCT_BY_CATEGORY}/${category}/${currentPage}`
      );

      setFullProductData(response.data);
      // setFullProductData(sortProducts(response.data));
    } catch (error) {
      console.log("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    FetchFullProductList();
  }, [currentPage, category]);

  const pageNumbers = [];
  for (let i = 1; i <= FullProductData?.totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleSortSelection = (e) => {
    setSortOption(e.target.value);
  };

  // MODAL LOGIN
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  return (
    <>
      <div>
        <div className={styles.fullproductContainer}>
          <div className={styles.productDetailsTopContainerNavigation}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <MdOutlineKeyboardArrowRight />
            </div>
            <div>
              <Link to={`/${category}`}>{category}</Link>
            </div>
          </div>
          {/* filter and heading  */}
          <div className={styles.fullproductHeading} id="pageTop">
            <div>
              <h2>{category}</h2>
            </div>

            <div className={styles.fullproductHeadingFilterIcons}>
              {/* featured  */}
              <select name="" id="" onChange={handleSortSelection}>
                <option value="Featured">Featured</option>
                <option value="priceAccending">Price, low to hight</option>
                <option value="priceDecending"> Price, high to low</option>
                <option value="AtoZ">Alphabetically, A-Z</option>
                <option value="ZtoA">Alphabetically, Z-A</option>
                <option value="NewToOld"> Date, new to old</option>
                <option value="OldToNew"> Date, old to new</option>
              </select>

              <div
                className={styles.filterButton}
                onClick={() => setOpenFilters(true)}
              >
                <img src={FilterIcon} alt="" />
                <p>Filters</p>
              </div>
            </div>
          </div>
          {/* product listing  */}
          <div>
            {FullProductData?.length === 0 ? (
              <div className={styles.fullProductNotFound}>
                <p>No products found</p>
              </div>
            ) : (
              <>
                <div className={styles.fullproductListContainer}>
                  <div className={styles.fullproductList}>
                    {!FullProductData
                      ? Array(15)
                          .fill()
                          .map((_, index) => (
                            <div
                              key={index}
                              className={styles.productSkeletonLoading}
                            >
                              {/* Skeleton Image */}
                              <Skeleton height={150} />
                              {/* Skeleton Category */}
                              <Skeleton
                                width={140}
                                style={{ marginTop: "14px" }}
                              />
                              {/* Skeleton Title */}
                              <Skeleton
                                width={150}
                                style={{ marginTop: "8px" }}
                              />
                              {/* Skeleton Price */}
                              <Skeleton
                                width={120}
                                style={{ marginTop: "8px" }}
                              />
                              {/* Skeleton Buttons */}
                              <div className={styles.skeletonLoadingButton}>
                                <Skeleton width={40} height={35} />
                                <Skeleton width={40} height={35} />
                              </div>
                            </div>
                          ))
                      : FullProductData?.products?.map((product) => (
                          <ProductCard
                            key={product.productId}
                            product={product}
                            openModal={openModal}
                          />
                        ))}
                  </div>
                </div>
                {/* paginations  */}
                <div className={styles.fullProductListPages}>
                  {/* Prev button */}
                  <div className={styles.PrevNextButton}>
                    {currentPage > 1 && (
                      <Link
                        to={`/${category}?page=${parseInt(currentPage) - 1}`}
                      >
                        Prev
                      </Link>
                    )}
                  </div>

                  {/* Page numbers */}
                  <div className={styles.pageNumbers}>
                    {pageNumbers.map((pageNumber) => (
                      <Link
                        key={pageNumber}
                        to={`/${category}?page=${pageNumber}`}
                      >
                        <p
                          className={`${styles.pageLink} ${
                            pageNumber == currentPage ? styles.activePage : ""
                          }`}
                        >
                          {pageNumber}
                        </p>
                      </Link>
                    ))}
                  </div>
                  {/* Next button */}
                  <div className={styles.PrevNextButton}>
                    {currentPage < pageNumbers.length && (
                      <Link
                        to={`/${category}?page=${parseInt(currentPage) + 1}`}
                      >
                        Next
                      </Link>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {isModalOpen && (
          <ProductDetailModal
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedProduct={selectedProduct}
          />
        )}
      </div>
    </>
  );
};

export default FullProduct;
