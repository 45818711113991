import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./Checkout.module.css";
import axios from "axios";
import { PAYMENT, PAYMENTVERIFY, VALIDATE_PROMO_CODE } from "../Apis";
import { UserContext } from "../../context/userContext";
import Loader from "../../assets/spinnerwhite.svg";

const Checkout = () => {
  const { cartData, Commisions } = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const promocode = queryParams.get("promocode");
  let accessToken = localStorage.getItem("BandhejHub");
  const [buttonLoader, setButtonLoader] = useState(false);
  const orderItems = () => {
    if (!cartData || !Array.isArray(cartData)) {
      console.error("cartData is not defined or not an array");
      return [];
    }
    return cartData.map((item) => ({
      sellerId: item?.variant?.product?.sellerId,
      quantity: item?.quantity,
      sku: item?.sku,
    }));
  };
  const [discount, setDiscount] = useState(0);

  const [prices, setPrices] = useState({
    subtotal: 0,
    total: 0,
  });

  const [shippingDetails, setshippingDetails] = useState({
    // country: "india",
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
  });
  const handleInput = (e) => {
    setshippingDetails({
      ...shippingDetails,
      [e.target.name]: e.target.value,
    });
  };
  const [billingDetails, setBillingDetails] = useState({
    // country: "india",
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
  });

  const handleInputBilling = (e) => {
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    const order = {
      shippingDetails: shippingDetails,
      billingDetails: billingDetails,
      orderItems: orderItems(),
    };
    const requestBody = {
      payment: Math.round(prices?.total),
      receipt: "sfojwoejfo30",
      order: order,
      shppingbilling: redioOption === "same" ? true : false,
      promoCode: prices?.subtotal === prices?.total ? "" : promocode,
    };

    try {
      const response = await axios.post(PAYMENT, requestBody, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      const options = {
        key: process.env.REACT_APP_PAYMENT_GATEWAY_KEY_ID,
        amount: response.data.amount,
        currency: "INR",
        name: "Bandhej Hub",
        description: "Secure transaction with Bandhej Hub",
        image:
          "https://www.bandhejhub.tech/static/media/bhlogo.c09fb499c8a716da94a8.png",
        order_id: response.data.id,
        callback_url: PAYMENTVERIFY,
        prefill: {
          name: shippingDetails.name,
          email: shippingDetails.email,
          contact: shippingDetails.phone,
        },
        notes: {
          address: shippingDetails.address,
        },
        theme: {
          color: "#1c5560",
        },
      };
      setButtonLoader(false);
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      setButtonLoader(false);
      console.log("Error: " + error);
    }
  };

  useEffect(() => {
    if (cartData && cartData?.length > 0) {
      const subtotal = cartData.reduce(
        (acc, item) =>
          acc + Commisions(item?.variant?.product.showPrice) * item.quantity,
        0
      );

      setPrices((prevPrices) => ({
        ...prevPrices,
        subtotal: Math.max(subtotal, 0),
      }));
    }
  }, [cartData]);
  const [redioOption, setRadioOption] = useState("same");

  const generateDiscount = useCallback(async () => {
    if (!promocode) {
      setPrices((prevPrices) => ({
        ...prevPrices,
        total: Math.max(prices.subtotal, 0),
      }));
      return;
    }

    try {
      const response = await axios.post(VALIDATE_PROMO_CODE, {
        promoCode: promocode,
        totalAmount: prices?.subtotal,
      });
      const newDiscount = response.data.discount;
      setDiscount(newDiscount);
      const newTotal = prices.subtotal - newDiscount;
      setPrices((prevPrices) => ({
        ...prevPrices,
        total: Math.max(newTotal, 0),
      }));
    } catch (error) {
      console.log(error);
      setPrices((prevPrices) => ({
        ...prevPrices,
        total: Math.max(prices.subtotal, 0),
      }));
    }
  }, [promocode, prices?.subtotal]); // Add prices?.subtotal to the dependency array

  useEffect(() => {
    generateDiscount();
  }, [generateDiscount]); // Only include generateDiscount here
  const handleChangeRadio = (e) => {
    setRadioOption(e.target.value);
  };
  return (
    <div className={styles.checkoutSection}>
      <div className={styles.checkoutDeliverySection}>
        <form method="POST" onSubmit={handleSubmit}>
          {/* delivery  */}
          <div className={styles.deliveryDetails}>
            <h4>Delivery Details</h4>
            <div>
              <input type="text" required value="India" disabled />
            </div>

            <div>
              <input
                type="text"
                required
                placeholder="Full Name"
                name="name"
                value={shippingDetails.name}
                onChange={handleInput}
              />
            </div>

            <div>
              <input
                type="text"
                required
                placeholder="Address"
                name="address"
                value={shippingDetails.address}
                onChange={handleInput}
              />
            </div>

            <div className={styles.checkoutDeliverySectionLandmarkInput}>
              <input
                type="text"
                required
                placeholder="City"
                name="city"
                value={shippingDetails.city}
                onChange={handleInput}
              />
              <input
                style={{ margin: "0 10px" }}
                type="text"
                required
                placeholder="State"
                name="state"
                value={shippingDetails.state}
                onChange={handleInput}
              />
              <input
                type="number"
                required
                placeholder="PIN code"
                name="pinCode"
                value={shippingDetails.pinCode}
                onChange={handleInput}
              />
            </div>
            <div>
              <input
                type="email"
                required
                placeholder="Email Address"
                name="email"
                value={shippingDetails.email}
                onChange={handleInput}
              />
            </div>
            <div>
              <input
                type="text"
                required
                placeholder="Phone Number"
                name="phone"
                value={shippingDetails.phone}
                onChange={handleInput}
              />
            </div>
          </div>
          {/* billing option  */}
          <div className={styles.billingtop}>
            <h4>Billing address</h4>
            <div className={styles.billingAddressSection}>
              <div className={styles.billingOption}>
                <label className={styles.billingOptionLabel}>
                  <input
                    type="radio"
                    name="billingAddress"
                    value="same"
                    defaultChecked
                    onChange={handleChangeRadio}
                  />
                  Same as shipping address
                </label>
              </div>
              <div className={styles.billingOption}>
                <label className={styles.billingOptionLabel}>
                  <input
                    type="radio"
                    name="billingAddress"
                    value="different"
                    onChange={handleChangeRadio}
                  />
                  Use a different billing address
                </label>
              </div>
            </div>
          </div>
          {/* billing address  */}
          {redioOption === "different" && (
            <div className={styles.deliveryDetails}>
              <div>
                <input type="text" required value="India" disabled />
              </div>

              <div>
                <input
                  type="text"
                  required
                  placeholder="Full Name"
                  name="name"
                  value={billingDetails.name}
                  onChange={handleInputBilling}
                />
              </div>

              <div>
                <input
                  type="text"
                  required
                  placeholder="Address"
                  name="address"
                  value={billingDetails.address}
                  onChange={handleInputBilling}
                />
              </div>

              <div className={styles.checkoutDeliverySectionLandmarkInput}>
                <input
                  type="text"
                  required
                  placeholder="City"
                  name="city"
                  value={billingDetails.city}
                  onChange={handleInputBilling}
                />
                <input
                  style={{ margin: "0 10px" }}
                  type="text"
                  required
                  placeholder="State"
                  name="state"
                  value={billingDetails.state}
                  onChange={handleInputBilling}
                />
                <input
                  type="number"
                  required
                  placeholder="PIN code"
                  name="pinCode"
                  value={billingDetails.pinCode}
                  onChange={handleInputBilling}
                />
              </div>

              <div>
                <input
                  type="email"
                  required
                  placeholder="Email Address"
                  name="email"
                  value={billingDetails.email}
                  onChange={handleInputBilling}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Phone Number"
                  name="phone"
                  value={billingDetails.phone}
                  onChange={handleInputBilling}
                />
              </div>
            </div>
          )}

          {/* submit button  */}
          {buttonLoader ? (
            <button className={styles.purchaseButtonLoader} disabled>
              {/* Loading... */}
              <img src={Loader} alt="" />
            </button>
          ) : (
            <div className={styles.checkoutDeliverySectionSubmitInput}>
              <input type="submit" value="Pay now" />
            </div>
          )}
        </form>
        {/* services  */}
        <div className={styles.services}>
          <p>Refund policy</p>
          <p>Shopping policy</p>
          <p>Terms of service</p>
        </div>
      </div>

      <div className={styles.checkoutCartSection}>
        <div>
          <table>
            <tbody>
              {cartData?.map((item) => (
                <tr className={styles.checkoutCartContent} key={item?.sku}>
                  <td>
                    <div className={styles.checkoutCartImagesDetail}>
                      <div>
                        <img src={item?.variant?.image} alt="Loading..." />
                        <span>{item?.quantity}</span>
                      </div>
                      <div className={styles.checkoutCartDetail}>
                        <p>{item?.variant?.product?.name}</p>
                        <p>Color : {item?.variant?.color}</p>
                        <p>
                          Rs. {Commisions(item?.variant?.product?.showPrice)}.00
                        </p>
                      </div>
                    </div>
                  </td>

                  <td>
                    Rs.
                    {Commisions(item?.variant?.product?.showPrice) *
                      item?.quantity}
                    .00
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        {/* amount  */}
        <div className={styles.cartAmount}>
          <div className={styles.cartAmountRupee}>
            <h3>Subtotal</h3>
            <h3>Rs.{prices?.subtotal}</h3>
          </div>
          {/* promocodsubtotale  */}
          <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
            <h3>Discount</h3>
            <h3>-Rs.{discount.toFixed(0)}</h3>
          </div>
          {/* delivery  */}
          <div className={styles.cartAmountRupee}>
            <h3>Delivery</h3>
            <h3>Rs.0</h3>
          </div>
          {/* total  */}
          <div className={styles.cartAmountRupee}>
            <h3>Total</h3>
            <h3>Rs.{Math.round(prices?.total)}</h3>
            {/* <h3>Rs.{Math.round(Number(prices?.subtotal) - discount)}</h3> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
