import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Wishlist.module.css";
import ProductDetailModal from "../../Modals/ProductDetails";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UserContext } from "../../context/userContext";
import WishlistCard from "./WishlistCard";

const Wishlist = () => {
  const {
    wishListData,
    removedWishlistItem,
    FetchWishlistDataLoader,
    setWishListData,
  } = useContext(UserContext);
  const navigate = useNavigate();
  document.title = `Wishlist - Bandhej Hub`;

  // MODAL LOGIN
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  const handleWishlistClick = (wishlistId) => {
    setWishListData((prevData) =>
      prevData.filter((item) => item.wishlistId !== wishlistId)
    );
    removedWishlistItem(wishlistId);
  };

  return (
    <>
      <div>
        <div className={styles.fullproductContainer}>
          {/* navigation  */}
          <div className={styles.productDetailsTopContainerNavigation}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <MdOutlineKeyboardArrowRight />
            </div>
            <div>
              <Link to={"/wishlist"}>Wishlist</Link>
            </div>
          </div>

          {/* product listing  */}
          <div>
            {FetchWishlistDataLoader ? (
              <div className={styles.skeletonLoading}>
                {Array(15)
                  .fill()
                  .map((_, index) => (
                    <div key={index} className={styles.productSkeletonLoading}>
                      {/* Skeleton Image */}
                      <Skeleton height={150} />
                      {/* Skeleton Category */}
                      <Skeleton width={140} style={{ marginTop: "14px" }} />
                      {/* Skeleton Title */}
                      <Skeleton width={150} style={{ marginTop: "8px" }} />
                      {/* Skeleton Price */}
                      <Skeleton width={120} style={{ marginTop: "8px" }} />
                      {/* Skeleton Buttons */}
                      <div className={styles.skeletonLoadingButton}>
                        <Skeleton width={40} height={35} />
                        <Skeleton width={40} height={35} />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                <div className={styles.fullproductListContainer}>
                  <div className={styles.fullproductList}>
                    {wishListData === null || wishListData.length === 0 ? (
                      <div className={styles.fullProductNotFound}>
                        <img src="/assets/NoWishList.png" alt="" />
                        <h3>Your wishlist is empty</h3>
                        <p>You have no items in your wishlist</p>
                        <p>Let's go add something</p>
                        <button onClick={() => navigate("/")}>Shop Now</button>
                      </div>
                    ) : (
                      wishListData?.map((product) => (
                        <WishlistCard
                          key={product.wishlistId}
                          product={product}
                          openModal={openModal}
                          removedWishlistItem={removedWishlistItem}
                          handleWishlistClick={handleWishlistClick}
                        />
                      ))
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {isModalOpen && (
          <ProductDetailModal
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedProduct={selectedProduct}
          />
        )}
      </div>
    </>
  );
};

export default Wishlist;
