import React from "react";
import styles from "./HeaderBanner.module.css";
const HeaderBanner = () => {
  return (
    <div className={styles.bannerContainer}>
      <img src="/assets/bhPosterHeader.jpg" alt="loading..." />
    </div>
  );
};

export default HeaderBanner;
