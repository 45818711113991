import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import styles from "./Order.module.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GET_ORDER } from "../Apis";
import { UserContext } from "../../context/userContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const MyOrders = () => {
  const { Commisions } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [screenLoader, setScreenLoader] = useState(false);
  let accessToken = localStorage.getItem("BandhejHub");
  const [orderData, setOrderData] = useState(null);
  // {FATCH ORDER DATA I}
  useEffect(() => {
    const fetchData = async () => {
      setScreenLoader(true);
      try {
        const response = await axios.get(`${GET_ORDER}/${status}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });
        setOrderData(response?.data.orders.reverse());
        setScreenLoader(false);
      } catch (error) {
        setScreenLoader(false);
        setOrderData("Order not found");
      }
    };

    if (accessToken) {
      fetchData();
    }
  }, [status, accessToken]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Set to IST (Indian Standard Time)
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const truncateOrderId = (orderId) => {
    const n = orderId.length;
    if (orderId) {
      return orderId.slice(n - 8, n);
    }
  };

  return (
    <div className={styles.myordersPage}>
      {/* Navigation Bar */}
      <div className={styles.productDetailsTopContainerNavigation}>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <MdOutlineKeyboardArrowRight />
        </div>
        <div>Orders</div>
      </div>

      {/* Category Buttons */}
      <div className={styles.myordersCategory}>
        {[
          "All",
          "Placed",
          "Confirm",
          "Dispatched",
          "Delivered",
          "Cancelled",
        ].map((itemStatus) => (
          <div
            key={itemStatus}
            onClick={() => navigate(`/myorders?status=${itemStatus}`)}
          >
            <button
              className={status === itemStatus ? styles.statusBtnActive : ""}
            >
              {itemStatus}
            </button>
          </div>
        ))}
      </div>

      {/* Order List or Not Found Conditions */}
      <div>
        {screenLoader ? (
          <div className={styles.cartLoadingMainSection}>
            {[...Array(3)].map((_, index) => (
              <div key={index} className={styles.cartLoadingSection}>
                <div>
                  <Skeleton height={110} width={100} />
                </div>
                <div>
                  {window.innerWidth > 550 ? (
                    <>
                      <Skeleton height={25} width={300} />
                      <Skeleton height={20} width={200} />
                      <Skeleton height={20} width={150} />
                    </>
                  ) : (
                    <>
                      <Skeleton height={22} width={150} />
                      <Skeleton height={20} width={100} />
                      <Skeleton height={20} width={60} />
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : orderData === "Order not found" || orderData === null ? (
          <div className={styles.ordernotfound}>
            <img
              src="https://img.freepik.com/premium-vector/order-closed-flat-vector-illustration-mobile-shopping-order-processing-status-icon-man-standing-near-huge-smartphone-with-shopping-cart-screen-digital-shopping-order-shipped-closed_241107-1213.jpg?semt=ais_hybrid"
              alt="Order not found"
            />
            <p>Oops! {status} Order Not Found</p>
            <Link to="/">
              <button>Order now</button>
            </Link>
          </div>
        ) : (
          <div className={styles.myordersList}>
            {orderData?.map((item) => (
              <div
                key={item?.orderItemId}
                onClick={() => navigate(`/myorders/${item?.orderItemId}`)}
                className={styles.myordersContainer}
              >
                {/* Order ID and Amount */}
                <div className={styles.myordersCotainer1}>
                  <div>
                    <p>Order ID: #{truncateOrderId(item?.orderItemId)}</p>
                    <p>Placed on: {formatDate(item?.order.createdAt)}</p>
                  </div>
                  <div>
                    Total ₹
                    {item?.quantity *
                      Commisions(item?.variant?.product?.showPrice)}
                    .00
                  </div>
                </div>

                {/* Product List */}
                <div className={styles.myordersCotainer2}>
                  <div className={styles.myordersContainer2Left}>
                    {/* Image */}
                    <div>
                      <img
                        src={item?.variant?.image}
                        alt={item?.variant?.product?.name || "Product"}
                      />
                    </div>
                    {/* Product Details */}
                    <div>
                      {/* Name and Status */}
                      <div>
                        <div>{item?.variant.product.name}</div>
                        <div
                          className={
                            item?.status === "Cancelled"
                              ? `${styles.myorderStatus} ${styles.myorderStatusCancel}`
                              : item?.status === "Delivered"
                              ? `${styles.myorderStatus} ${styles.myorderStatusDelivered}`
                              : styles.myorderStatus
                          }
                        >
                          {item?.status}
                        </div>
                      </div>
                      {/* Category and Quantity */}
                      <p>
                        {item?.variant.product.category} | Item:{" "}
                        {item?.quantity}
                      </p>
                      {/* Price */}
                      <p>
                        Price: ₹{Commisions(item?.variant.product.showPrice)}.00
                      </p>
                    </div>
                  </div>
                  <div className={styles.myordersContainer2Right}>
                    <i>
                      <MdOutlineKeyboardArrowRight />
                    </i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
